var DropdownData = [
    {
        id:1,
        item1: "Lorem ipsum",
        item2: "Lorem ipsum",
        item3: "Lorem ipsum",
        item4: "Lorem ipsum",
        item5: "Lorem ipsum"
    },
    {
        id:2,
        item1: "Lorem ipsum",
        item2: "Lorem ipsum",
        item3: "Lorem ipsum",
        item4: "Lorem ipsum",
        item5: "Lorem ipsum"
    },
    {
        id:3,
        item1: "Lorem ipsum",
        item2: "Lorem ipsum",
        item3: "Lorem ipsum",
        item4: "Lorem ipsum",
        item5: "Lorem ipsum"
    },
    {
        id:4,
        item1: "Lorem ipsum",
        item2: "Lorem ipsum",
        item3: "Lorem ipsum",
        item4: "Lorem ipsum",
        item5: "Lorem ipsum"
    },
    {
        id:5,
        item1: "Lorem ipsum",
        item2: "Lorem ipsum",
        item3: "Lorem ipsum",
        item4: "Lorem ipsum",
        item5: "Lorem ipsum"
    },
    {
        id:6,
        item1: "Lorem ipsum",
        item2: "Lorem ipsum",
        item3: "Lorem ipsum",
        item4: "Lorem ipsum",
        item5: "Lorem ipsum"
    }
    

];
export default DropdownData;