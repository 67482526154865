import React from "react";
import Item from "./components/Item/Item";
import "./MultiCollapsable.scss";

import { FiArrowLeft } from "react-icons/fi";

export default function MultiCollapsable({ isExpanded, setIsExpanded }) {
    const items = {
        0: [
            {
                title: "Item 1",
                nexItemIndex: 1,
            },
            {
                title: "Item 2",
                nexItemIndex: 2,
            },
            {
                title: "Item 3",
            },
        ],
        1: [
            {
                title: "Sub Item 1",
                nexItemIndex: 3,
            },
            {
                title: "Sub Item 2",
            },
            {
                title: "Sub Item 3",
            },
        ],
        2: [
            {
                title: "Sub Item hj",
            },
            {
                title: "Sub Item 2hghg",
            },
            {
                title: "Sub Item 3hg",
            },
        ],
        3: [
            {
                title: "Sub Item ",
            },
            {
                title: "Sub Item 4444",
            },
            {
                title: "Sub Item 444",
            },
        ],
    };

    const [currentItem, setCurrentItem] = React.useState(0);
    const [previousItem, setPreviousItem] = React.useState([]);

    const MultiItems = ({ nitem, setNextPrev, isActive }) => {
        return (
            <div
                className={`multiCollapsableItems ${isActive ? "active" : ""}`}
            >
                {nitem?.map((text, index) => (
                    <Item
                        onClick={(e) => {
                            e.stopPropagation();
                            if (nitem[index]?.nexItemIndex)
                                setNextPrev(nitem[index].nexItemIndex);
                        }}
                        key={index}
                    >
                        {text?.title}
                    </Item>
                ))}
            </div>
        );
    };

    console.log(currentItem, previousItem);

    const CollapseContent = () => {
        return (
            <div className="multiCollapsableContent">
                {previousItem.length > 0 && (
                    <Item
                        onClick={(e) => {
                            e.stopPropagation();

                            const newPreviousItem = previousItem.slice();
                            const prev = newPreviousItem.pop();
                            setCurrentItem(prev * 1);
                            setPreviousItem(newPreviousItem);
                        }}
                    >
                        <FiArrowLeft size={20} /> prev
                    </Item>
                )}
                {Object.keys(items).map((key, index) => {
                    return (
                        <MultiItems
                            key={index}
                            nitem={items[key]}
                            setNextPrev={(pre) => {
                                setPreviousItem([...previousItem, currentItem]);

                                setCurrentItem(pre * 1);
                            }}
                            isActive={currentItem === key * 1}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div
            className={`multiCollapsable ${isExpanded ? "active" : ""}`}
            onMouseDown={(e) => {
                e.stopPropagation();
            }}
        >
            <h5 onClick={setIsExpanded} className="multiCollapsableTitle">
                MultiCollapsable
            </h5>
            {isExpanded && <CollapseContent />}
        </div>
    );
}
