import App from '../App';
import Newheader from '../components/Newheader';
import Devops from '../components/Devops';

function Home() {
     return (
          <>
               <Newheader />
               <Devops />
          </>
     )
}

export default Home;