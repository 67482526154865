import React from "react";
import LeftSideBarHeader from "./components/LeftSideBarHeader/LeftSideBarHeader";
import LeftSideContent from "./components/LeftSideContent/LeftSideContent";

export default function SidebarLeft({ onClick, active }) {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className="left">
            <LeftSideBarHeader onToggle={() => setIsOpen((prev) => !prev)} />
            <LeftSideContent
                isOpen={isOpen}
                onClick={onClick}
                active={active}
                onItemClick={(e) => {
                    e.stopPropagation();
                    setIsOpen((prev) => !prev);
                }}
            />
        </div>
    );
}
