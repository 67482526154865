import React from "react";
import { BsList } from "react-icons/bs";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./LeftSidebarHeader.scss";

export default function LeftSideBarHeader({ onToggle }) {
    const navigate = useNavigate();
    return (
        <div className="left-header d-flex justify-content-between px-4">
            <div
                onClick={() => navigate(-1)}
                className="d-flex justify-content-left align-items-center gap-3 p-1"
            >
                <span className="leftArrow">
                    <HiArrowLeft size={24} color="#67B1FC" />
                </span>
                <h4 className="leftHeaderTitle">ASSETS LIST</h4>
            </div>
            <button className="leftToggle" onClick={onToggle}>
                <BsList size={24} color="#67B1FC" />
            </button>
        </div>
    );
}
