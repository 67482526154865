import React, { useState } from "react";
import dropdownData from "../../data/DropdownData";
import data from "../../data/ServicesListData";
import MultiDropDown from "./components/MultiDropDown/MultiDropDown";
import ServiceHeader from "./components/ServiceHeader/ServiceHeader";
import ServiceSearch from "./components/ServiceSearch/ServiceSearch";
import ServicesList from "./components/ServicesList/ServicesList";
import "./Services.scss";
import { useNavigate } from "react-router-dom";
import MultiCollapsable from "./components/MultiCollapsable/MultiCollapsable";
import { BsList } from "react-icons/bs";

const Services = () => {
    const [services, setServices] = useState([]);
    const [dropdown, setDropdown] = useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        setServices(data);
        setDropdown(dropdownData);
    }, []);

    const handleLike = (id, e) => {
        e.stopPropagation();
        const servece = services.find((service) => service.id === id);
        const likes = servece.likes + 1;
        const newServices = services.map((service) => {
            if (service.id === id) {
                return { ...service, likes };
            }
            return service;
        });
        setServices(newServices);
    };

    const handleDislike = (id, e) => {
        e.stopPropagation();
        const servece = services.find((service) => service.id === id);
        const likes = servece.likes - 1;
        const newServices = services.map((service) => {
            if (service.id === id) {
                return { ...service, likes };
            }
            return service;
        });
        setServices(newServices);
    };

    const [expandedItem, setExpandedItem] = useState(null);

    React.useEffect(() => {
        document.body.addEventListener("mousedown", (e) => {
            e.stopPropagation();
            setExpandedItem(null);
        });
    }, []);
    const [showExpandable, setShowExpandable] = useState(false);

    return (
        <div className="services">
            <div className="container py-5">
                <div className={`services ${showExpandable ? "show" : ""}`}>
                    <div className="servicesToggler px-3 py-3 d-flex justify-content-between align-items-center">
                        <h5 className="m-0">Services</h5>
                        <button
                            className="leftToggle"
                            onClick={() => setShowExpandable((prev) => !prev)}
                        >
                            <BsList size={24} color="#67B1FC" />
                        </button>
                    </div>

                    <div className="multiDropDownItems">
                        {dropdown.map((drop, key) => (
                            <MultiCollapsable
                                isExpanded={expandedItem === key}
                                key={key}
                                setIsExpanded={() => {
                                    setExpandedItem(
                                        expandedItem === key ? null : key
                                    );
                                }}
                            />
                        ))}
                    </div>
                </div>
                <ServiceHeader title={"Service Hub"} />

                <ServiceSearch />

                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {services.map((service, index) => (
                        <ServicesList
                            key={index}
                            onLike={(e) => handleLike(service.id, e)}
                            onDislike={(e) => handleDislike(service.id, e)}
                            image={service.image}
                            connector={service.connector}
                            likes={service.likes}
                            organization={service.organization}
                            version={service.version}
                            onClick={() =>
                                navigate(`/dashboard?id=${service.id}`)
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
