import salesforce from "../img/services/Salesforce.com_logo.svg.png";

const ServicesListData = [
    {
        id: 1,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 2,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 3,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 4,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 5,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 6,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 7,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 8,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },
    {
        id: 9,
        likes: 36,
        image: salesforce,
        version: "Version:10.14x",
        connector: "Salesforce Connector - Mule 4",
        organization: "Mulesoft Organization",
    },

];


export default ServicesListData;
