import React from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import LeftSideLink from "./components/LeftSideLink/LeftSideLink";

import "./LeftSideContent.scss";

export default function LeftSideContent({ onItemClick, isOpen }) {
    const [active, setActive] = React.useState("summary");

    const location = useLocation();

    React.useEffect(() => {
        if (location.hash) {
            setActive(location.hash);
        }
    }, [location]);

    return (
        <div className={`leftSideNavContent mt-4 ${isOpen ? "show" : ""}`}>
            {/* apiSummary apiEndpoints */}
            <LeftSideLink
                title="Specfications"
                link="/dashboard/#summary"
                isActive={active === "#summary"}
                onClick={(e) => {
                    onItemClick?.();
                }}
            />
            <LeftSideLink
                title="Endpoints"
                link={`/dashboard/#endpoints`}
                isActive={active === "#endpoints"}
                onClick={(e) => {
                    onItemClick?.();
                }}
            />
            <LeftSideLink link={"#types"} title="Types" onClick={onItemClick} />
            <LeftSideLink
                link={"#security"}
                title="Security"
                onClick={onItemClick}
            />
            <LeftSideLink
                link={"#summary"}
                title="Summary"
                onClick={onItemClick}
            />

            <LeftSideLink
                link={"#apiinstances"}
                title="API instances"
                onClick={onItemClick}
            />
        </div>
    );
}
